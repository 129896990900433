import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-choose-nfc-design',
  templateUrl: './choose-nfc-design.component.html',
  styleUrls: ['./choose-nfc-design.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]

})
export class ChooseNfcDesignComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('nfcCardStyle',
      this.formBuilder.group({
        front: this.formBuilder.group({one: null, two: null, three: null, four: null, five: null, six: null}),
        back: this.formBuilder.group({one: null, two: null, three: null, four: null, five: null, six: null})
      })
    );
  }

}
