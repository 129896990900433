import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BasicFormGuard} from "./customer-form/guard/badic-form-guard.service";
import {PaymentReceivedComponent} from "./payment-received/payment-received.component";
import {FormBasicComponent} from "./customer-form/form-basic/form-basic.component";
import {FormSmartComponent} from "./customer-form/form-smart/form-smart.component";
import {BuyBasicComponent} from "./buy-products/buy-basic/buy-basic.component";
import {BuySmartComponent} from "./buy-products/buy-smart/buy-smart.component";
import {SmartFormGuard} from "./customer-form/guard/smart-form-guard.service";

const routes: Routes = [

  // Basic routes
  {path: 'basic-buy', component: BuyBasicComponent},
  {path: 'basic-form', component: FormBasicComponent, canActivate: [BasicFormGuard]},
  // {path: 'basic-form-dev', component: FormBasicComponent},

  // Smart routes
  {path: 'smart-buy', component: BuySmartComponent},
  {path: 'smart-form', component: FormSmartComponent, canActivate: [SmartFormGuard]},
  // {path: 'smart-form-dev', component: FormSmartComponent},

  // Payment routes
  {path: 'payment-received', component: PaymentReceivedComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
