<a href="https://linqed.de">
  <img src="assets/img/default-monochrome.svg" style="width: 200px;margin-top: 10px;margin-right: 0;margin-left: 10px;" alt="Linqed.de Logo">
</a>

<div class="container mb-5 mt-5">
  <div class="pricing card-deck flex-column flex-md-row mb-3">
    <div class="card card-pricing text-center px-3 mb-4">
      <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white shadow-sm">Basic</span>
      <div class="bg-transparent card-header pt-4 border-0">
        <h1 class="h1 font-weight-normal text-primary text-center mb-0" data-pricing-value="15">

          <!-- Pricing -->
          <span class="price">{{basicProduct.price.priceGross}} €</span><span class="h6 text-muted ml-2">/ pro Jahr</span>
          <!-- end -->

        </h1>
      </div>
      <div class="card-body pt-0">

        <!-- Features -->
        <ul class="list-unstyled mb-4 boldList">
          <li>1x LINQED.de basic Card Website</li>
          <li>3x personalisierte QR-Codes</li>
        </ul>
        <!-- end -->

        <!-- Features -->
        <ul class="list-unstyled mb-4">
          <li>Verfügbar innerhalb von 48 Stunden</li>
          <li>Überprüfung der Daten durch unser Team</li>
          <li>Auswahl aus allen Basic Designs</li>
        </ul>
        <!-- end -->

        <!-- Features -->
        <ul class="list-unstyled mb-4">
          <li>Deine Inhalte</li>
          <li>Dein Profilbild</li>
          <li>Dein Logo</li>
        </ul>
        <!-- end -->

        <p>Auf Anfrage können kleine Änderungen am gewählten Design gegen Aufpreis vorgenommen werden.</p>
        <!-- end -->

        <!-- paypal -->
        <div [hidden]="didPay" #paypal></div>
        <!-- end -->

        <!-- redirect -->
        <div [hidden]="!didPay">
          <p>Vielen Dank für Ihre Zahlung. Sollten Sie nicht automatisch weitergeleitert werden betätigen Sie bitte den Button.</p>
          <button (click)="changeRoute()">Zur Dateneingabe</button>
        </div>

        <div *ngIf="didPay">
          {{changeRoute()}}
        </div>
        <!-- redirect  end -->

        <!-- Features -->
        <ul class="list-unstyled mb-4 legal">
          <li>Alle Preise verstehen sich inkl. 19% Ust.</li>
          <li><a href="https://linqed.de/widerrufsbelehrung" target="_blank">Widerrufsbelehrung</a> </li>
        </ul>
        <!-- end -->

      </div>
    </div>
  </div>
</div>
