<!-- CONTACT GROUP -->
<h2>Kontakt</h2>
<div formGroupName="contact" class="form-row">

  <!-- email -->
  <div class="form-group col-lg-3 col-md-push-6">
    <label for="email">E-mail *</label>
    <input type="text" formControlName="email" id="email" class="form-control"/>
    <div *ngIf="form.get('contact.email').invalid && ( form.get('contact.email').touched)"
         class="invalid-feedback d-block">
      <div *ngIf="form.get('contact.email').errors.required">E-Mail wird benötigt.</div>
      <div *ngIf="form.get('contact.email').errors.pattern">Die E-Mail ist nicht richtig formatiert.
      </div>
    </div>
  </div>
  <!-- email end -->

  <!-- phone -->
  <div class="form-group col-lg-3 col-md-pull-6">
    <label for="phone">Festnetz-Rufnummer</label>
    <input type="text" formControlName="phone" id="phone" class="form-control"/>
  </div>
  <!-- phone end -->

  <!-- fax -->
  <div class="form-group col-lg-3 col-md-push-6">
    <label for="fax">Telefax-Rufnummer</label>
    <input type="text" formControlName="fax" id="fax" class="form-control"/>
  </div>
  <!-- fax end -->

  <!-- mobile -->
  <div class="form-group col-lg-3 col-md-pull-6">
    <label for="mobile">Mobilfunk-Rufnummer</label>
    <input type="text" formControlName="mobile" id="mobile" class="form-control"/>
  </div>
  <!-- mobile end -->

</div>
<!-- CONTACT GROUP END -->
