import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerButtonsProps {
  label: string
  url: string
}

export class CustomerButtons extends ValueObject<CustomerButtonsProps> {

  private constructor(props: CustomerButtonsProps) {
    super(props);
  }

  public static create(props: CustomerButtonsProps): CustomerButtons {
    return new CustomerButtons({...props})
  }

  public static initEmpty(): CustomerButtons {
    return CustomerButtons.create({label: "", url: ""})
  }

  public static createFromForm(formData: FormGroup): CustomerButtons {
    if (formData.get('button')) {
      return CustomerButtons.create({
        label: formData.get('button.buttonLabel').value,
        url: formData.get('button.buttonFunction').value
      })
    } else {
      return CustomerButtons.initEmpty()
    }
  }

}
