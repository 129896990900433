<a href="https://linqed.de">
  <img src="assets/img/default-monochrome.svg" style="width: 200px;margin-top: 10px;margin-right: 0;margin-left: 10px;"
       alt="Linqed.de Logo">
</a>


<div class="container">
  <!-- main app container -->
  <div class="card m-3">


    <h1 class="text-center">Get LINQED - Anmeldung Basic</h1>
    <div class="card-body">

      <p class="text-justify">
        Bitte gebe nun deine Daten ein, die du auf deiner LINQED.de basic Card hinterlegen möchtest. Die meisten Felder
        sind optional und nicht zwingend erforderlich. Du alleine entscheidest welche Daten genutzt werden!
        <br>
        <br>
        Du kannst dich jederzeit an uns wenden, falls du Änderungen vornehmen möchtest!
      </p>


      <form [formGroup]="customerForm" (ngSubmit)="onSubmit()">

        <app-choose-design></app-choose-design>

        <app-choose-wallet-design></app-choose-wallet-design>

        <app-choose-qr-style></app-choose-qr-style>

        <app-file-upload></app-file-upload>

        <app-personal-data></app-personal-data>

        <app-personal-data-contact></app-personal-data-contact>

        <app-job-data></app-job-data>

        <app-personal-data-company></app-personal-data-company>

        <app-social-media></app-social-media>

        <app-userdefined-buttons></app-userdefined-buttons>

        <app-user-message-to-us></app-user-message-to-us>

        <app-legal-consent></app-legal-consent>

        <!-- Submit -->
        <div class="form-group text-center">
          <br>
          <button class="btn btn-primary text-center" type="submit">
            Daten übermitteln
          </button>
          <div *ngIf="isInvalid() && submitted" class="invalid-feedback d-block">Bitte überprüfen Sie ihre Eingaben.
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 text-center">
            <img *ngIf="isValid() && loading" class="spinner" src="assets/img/loading.gif" alt="loading ..."/>
          </div>
        </div>

        <!--  <button type="submit" [disabled]="!customerForm.valid">Submit</button>-->
      </form>
    </div>
  </div>

  <!-- debug information -->

  <div *ngIf="!isProduction" class="invalid-feedback d-block">
    <h1> form data </h1>
    <pre class="debug">
      {{customerForm.value | json}}
      Form Status: {{ customerForm.status }}
    </pre>

  </div>

</div>
