<!-- NAME GROUP -->
<h2>Persönliche Daten</h2>
<div formGroupName="name" class="form-row">

  <!-- title -->
  <div class="form-group col-lg-2 col-sm-push-6">
    <label for="title">Titel</label>
    <input type="text" formControlName="title" id="title" class="form-control"/>
  </div>
  <!-- title end -->

  <!-- firstName -->
  <div class="form-group col-lg-5 col-sm-push-6">
    <label for="firstName">Vorname *</label>
    <input type="text" formControlName="firstName" id="firstName" class="form-control"/>

    <div
      *ngIf="form.get('name.firstName').invalid && (form.get('name.firstName').dirty || form.get('name.firstName').touched)"
      class="invalid-feedback d-block">
      <div *ngIf="form.get('name.firstName').errors.required">Vorname wird benötigt.</div>
    </div>
  </div>
  <!-- firstName end -->

  <!-- givenName -->
  <div class="form-group col-lg-5 col-sm-push-6">
    <label for="givenName">Nachname *</label>
    <input type="text" formControlName="givenName" id="givenName" class="form-control"/>

    <div
      *ngIf="form.get('name.givenName').invalid && (form.get('name.givenName').dirty || form.get('name.givenName').touched)"
      class="invalid-feedback d-block">
      <div *ngIf="form.get('name.givenName').errors.required">Nachname wird benötigt.</div>
    </div>
  </div>
  <!-- givenName end -->

</div>
<!-- NAME GROUP  end -->
