import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerPersonalAddressProps {
  street: string;
  streetNr: string;
  postalCode: string;
  city: string;
  stateOrProvince: string;
  country: string;
}

export class CustomerPersonalAddress extends ValueObject<CustomerPersonalAddressProps> {
  private constructor(props: CustomerPersonalAddressProps) {
    super(props);
  }

  public static create(props: CustomerPersonalAddressProps): CustomerPersonalAddress {
    return new CustomerPersonalAddress({...props})
  }

  public static initEmpty(): CustomerPersonalAddress {
    return CustomerPersonalAddress.create({
      city: "",
      country: "",
      postalCode: "",
      stateOrProvince: "",
      street: "",
      streetNr: ""
    })
  }

  public static createFromFrom(formData: FormGroup): CustomerPersonalAddress {
    if (formData.get('personal')) {
      return CustomerPersonalAddress.create({
        city: formData.get('personal.city').value,
        country: formData.get('personal.country').value,
        postalCode: formData.get('personal.postalCode').value,
        stateOrProvince: formData.get('personal.stateOrProvince').value,
        street: formData.get('personal.street').value,
        streetNr: formData.get('personal.streetNr').value
      })
    } else {
      return CustomerPersonalAddress.initEmpty()
    }
  }
}
