import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-nfc-file-upload',
  templateUrl: './nfc-file-upload.component.html',
  styleUrls: ['./nfc-file-upload.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class NfcFileUploadComponent implements OnInit {

  @Input() uploadNfcFile: File = null;

  @ViewChild('labelNfcFileDesign')
  labelNfcFileDesign: ElementRef;

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('nfcFileUpload',
      this.formBuilder.group({
        nfcDesignFile: [null],
      }));
  }

  onNfcFileChangeDesign(event) {
    const files = event.target.files;
    this.setLabelNfcFile(files);

    this.uploadNfcFile = files.item(0);
    console.log('nfcFile:', this.uploadNfcFile)
  }

  setLabelNfcFile(files: FileList) {
    this.labelNfcFileDesign.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
  }

}
