import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerPersonalContactProps {
  phone: string;
  fax: string;
  mobile: string;
  email: string;
}

export class CustomerPersonalContact extends ValueObject<CustomerPersonalContactProps> {

  private constructor(props: CustomerPersonalContactProps) {
    super(props);
  }

  public static create(props: CustomerPersonalContactProps): CustomerPersonalContact {
    return new CustomerPersonalContact({...props})
  }

  public static initEmpty(): CustomerPersonalContact {
    return CustomerPersonalContact.create({email: "", fax: "", mobile: "", phone: ""})
  }

  public static createFromForm(formData: FormGroup): CustomerPersonalContact {
    if (formData.get('contact')) {
      return CustomerPersonalContact.create({
          email: formData.get('contact.email').value,
          fax: formData.get('contact.fax').value,
          mobile: formData.get('contact.mobile').value,
          phone: formData.get('contact.phone').value
      })
    } else {
      return CustomerPersonalContact.initEmpty()
    }
  }


}
