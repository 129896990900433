import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerSocialMediaUrlProps {
  socialMedia1: string;
  socialMedia2: string;
  socialMedia3: string;
  socialMedia4: string;
  socialMedia5: string;
  socialMedia6: string;
}

export class CustomerSocialMediaUrl extends ValueObject<CustomerSocialMediaUrlProps> {

  private constructor(props: CustomerSocialMediaUrlProps) {
    super(props);
  }

  public static create(props: CustomerSocialMediaUrlProps): CustomerSocialMediaUrl {
    return new CustomerSocialMediaUrl({...props})
  }

  public static initEmpty(): CustomerSocialMediaUrl {
    return CustomerSocialMediaUrl.create({
      socialMedia1: "",
      socialMedia2: "",
      socialMedia3: "",
      socialMedia4: "",
      socialMedia5: "",
      socialMedia6: ""
    })
  }

  public static createFromForm(formData: FormGroup): CustomerSocialMediaUrl {
    if (formData.get('socialMedia')) {
      return CustomerSocialMediaUrl.create({
        socialMedia1: formData.get('socialMedia.socialMedia1').value,
        socialMedia2: formData.get('socialMedia.socialMedia2').value,
        socialMedia3: formData.get('socialMedia.socialMedia3').value,
        socialMedia4: formData.get('socialMedia.socialMedia4').value,
        socialMedia5: formData.get('socialMedia.socialMedia5').value,
        socialMedia6: formData.get('socialMedia.socialMedia6').value,
      })
    } else {
      return CustomerSocialMediaUrl.initEmpty()
    }
  }

}
