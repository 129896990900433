import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class SocialMediaComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('socialMedia',
      this.formBuilder.group({
        socialMedia1: [null],
        socialMedia2: [null],
        socialMedia3: [null],
        socialMedia4: [null],
        socialMedia5: [null],
        socialMedia6: [null],
      }),
    );
  }

}
