<div class="container">

  <div class="row">
    <div class="col-12">
      <img class="img-fluid logo mx-auto d-block" src="assets/img/default-monochrome.svg">
    </div>
  </div>

  <!-- main app container -->
  <div class="card m-3">
    <div class="card-body">

      <h1 class="text-center">Vielen Dank</h1>
      <p class="text-center">Sie erhalten in Kürze eine Rechnung per Email. Für weitere Fragen nutzen Sie bitte unser Kontaktformular.</p>


      <div class="col">

        <a href="{{changeRoute()}}">
          <button class="btn btn-primary text-center mx-auto d-block">
            Zurück zu Linqed.de
          </button>
        </a>


      </div>


    </div>
  </div>

</div>
