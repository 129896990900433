import { Component, OnInit } from '@angular/core';
import {DesignCellphone} from "../../entity/design.cellphone";
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-choose-design',
  templateUrl: './choose-design.component.html',
  styleUrls: ['./choose-design.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ChooseDesignComponent implements OnInit {

  images: DesignCellphone[];
  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.images = [
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5ecd6fe11dfd955d19fcadd8_Design1%20Apple%20iPhone%20XR%20Black.png",
        label: "Concrete",
        description: "http://linqed.de/concrete"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5edbc8ebb414c79698a75f1b_Beispiel%20cactus%20design-p-500.png",
        label: "Cactus",
        description: "http://linqed.de/cactus"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5ecd6fdec991ec4ed4494052_Design4 Apple iPhone XR Black-p-800.png",
        label: "Prim",
        description: "http://linqed.de/prim"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5ee36dc4175d3350a567022c_Beispiel%20Indigo%20design-p-800.png",
        label: "Indigo",
        description: "http://linqed.de/indigo"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5ecd6fe3bd7addb286deeca3_Design7%20Apple%20iPhone%20XR%20Black.png",
        label: "Black Rock",
        description: " http://linqed.de/blackrock"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5ecd6fdbfe83f422e0cd33c5_Design6%20Apple%20iPhone%20XR%20Black.png",
        label: "Nero",
        description: "http://linqed.de/nero"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5ecd6fdd13c35575979ac36f_Design5%20Apple%20iPhone%20XR%20Black.png",
        label: "Maize",
        description: "http://linqed.de/maize"
      },
      {
        imgSrc: "https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5edbc8ed88219c1ed7a724d6_Beispiel%20zodiac%20design.png",
        label: "Zodiac",
        description: "http://linqed.de/zodiac"
      },
    ];

    this.form.addControl('chooseDesignForm',
      this.formBuilder.group({
        design: ["Bitte wählen"],
      })
    );
  }

}
