<!-- WALLET STYLES -->
<div formGroupName="chooseWalletForm" class="form-row">

  <h2>Wallet-Design Auswahl</h2>
  <p class="text-justify">Du kannst aus drei unterschiedlichen Wallet-Designs auswählen. Solltest du keine Wallet Card haben wollen, lasse die Auswahl einfach frei. Falls du kein Profilbild hochlädst, bleibt der Bereich einfach frei.</p>
  <div class="col form-row">
    <div class="col text-center">
      <img src="assets/img/wallet_mockup_black.png" style="width: 100px;">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="black" formControlName="black" value="1" id="wallet_black">
        <label class="form-check-label" for="black">schwarz</label>
      </div>
    </div>
    <div class="col text-center">
      <img src="assets/img/wallet_mockup_white.png" style="width: 100px;">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="white" formControlName="white" value="1" id="wallet_white">
        <label class="form-check-label" for="white">weiß</label>
      </div>
    </div>
    <div class="col text-center">
      <img src="assets/img/wallet_mockup_blue.png" style="width: 100px;">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="blue" formControlName="blue" value="1" id="wallet_blue">
        <label class="form-check-label" for="blue">blau</label>
      </div>
    </div>
  </div>
</div>
