export class Price {

  _price: number; // Price without vat
  vatPercent: number;

  constructor(priceNet: number, vatPercent: number) {
    this._price = priceNet;
    this.vatPercent = vatPercent;
  }

  /**
   * Get the price with vat included
   */
  get priceGross() {
    return Math.round((this._price * (1 + this.vatPercent)) * 100)/100;
  }
}
