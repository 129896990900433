import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerQrCodeStyleProps {
  style01: boolean,
  style02: boolean,
  style03: boolean,
  style04: boolean,
  style05: boolean,
  style06: boolean,
}

export class CustomerQrCodeStyle extends ValueObject<CustomerQrCodeStyleProps> {

  private constructor(props: CustomerQrCodeStyleProps) {
    super(props);
  }

  public static create(props: CustomerQrCodeStyleProps): CustomerQrCodeStyle {
    return new CustomerQrCodeStyle({...props})
  }

  public static initEmpty(): CustomerQrCodeStyle {
    return CustomerQrCodeStyle.create({
      style01: false,
      style02: false,
      style03: false,
      style04: false,
      style05: false,
      style06: false
    })
  }

  public static createFromForm(formData: FormGroup): CustomerQrCodeStyle {
    if (formData.get('qrCode')) {
      return CustomerQrCodeStyle.create({
        style01: formData.get('qrCode.style01').value,
        style02: formData.get('qrCode.style02').value,
        style03: formData.get('qrCode.style03').value,
        style04: formData.get('qrCode.style04').value,
        style05: formData.get('qrCode.style05').value,
        style06: formData.get('qrCode.style06').value
      })
    } else {
      return CustomerQrCodeStyle.initEmpty()
    }
  }
}
