import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerJobProps {
  jobTitle: string;
  department: string;
}

export class CustomerJob extends ValueObject<CustomerJobProps> {

  private constructor(props: CustomerJobProps) {
    super(props);
  }

  public static create(props: CustomerJobProps): CustomerJob {
    return new CustomerJob({...props})
  }

  public static initEmpty(): CustomerJob {
    return CustomerJob.create({department: "", jobTitle: ""})
  }

  public static createFromForm(formData: FormGroup): CustomerJob {
    if (formData.get('job')) {
      return CustomerJob.create({
          department: formData.get('job.department').value,
          jobTitle: formData.get('job.title').value
      })
    } else {
      return CustomerJob.initEmpty()
    }
  }

}
