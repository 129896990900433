import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartFormGuard implements CanActivate {

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(((resolve, reject) => {
      this.didPay().then((bool) => {
        resolve(bool);
      });
    }));
  }

  // For future versions call backend with paypal rest call
  async didPay(): Promise<boolean> {
    const userDidPay = JSON.parse(localStorage.getItem('userDidPaySmart'));
    console.log(userDidPay);
    return userDidPay.didPay;
  }

}
