import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";

@Component({
  selector: 'app-personal-data-contact',
  templateUrl: './personal-data-contact.component.html',
  styleUrls: ['./personal-data-contact.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})

export class PersonalDataContactComponent implements OnInit {
  form: FormGroup;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl('contact',
      this.formBuilder.group({
        email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
        phone: [null],
        fax: [null],
        mobile: [null]
      })
    );
  }

}
