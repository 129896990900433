import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";

@Component({
  selector: 'app-legal-consent',
  templateUrl: './legal-consent.component.html',
  styleUrls: ['./legal-consent.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]

})
export class LegalConsentComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('consent',
      this.formBuilder.group({
        didAcceptPrivacyPolicy: [false, Validators.pattern('true')],
        didAcceptTermsAndConditions: [false, Validators.pattern('true')],
        didAcceptNewsletter: [false],
      })
    );
  }

}
