import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-userdefined-buttons',
  templateUrl: './userdefined-buttons.component.html',
  styleUrls: ['./userdefined-buttons.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]

})
export class UserdefinedButtonsComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('button',
      this.formBuilder.group({
        buttonLabel: [null],
        buttonFunction: [null]
      }),
    );

  }

}
