<!-- USER BUTTON -->
<h2>Buttons</h2>

<div formGroupName="button" class="form-row">

  <div class="form-group col-sm-12">
    <img class="img-fluid button-image" src="assets/img/buttons.png" alt="button example">
    <p>Falls du auf deiner LINQED basic Card einen Button einbauen möchtest, gebe unten den Text und das Profil
      bzw. die gewünschte Funktion an.</p>
  </div>

  <div class="form-group col-sm-12">
    <label for="buttonLabel">Benutzerdefinierter Button Label</label>
    <input type="text" formControlName="buttonLabel" id="buttonLabel" class="form-control"
           placeholder="z.B. Tisch reservieren"/>
  </div>

  <div class="form-group col-sm-12">
    <label for="buttonFunction">Benutzerdefinierter Button Link zum Funktionsaufruf</label>
    <input type="text" formControlName="buttonFunction" id="buttonFunction" class="form-control"
           placeholder="z.B. www.opentable.com/restaurant/id?=123"/>
  </div>

</div>
<!-- USER BUTTON END -->
