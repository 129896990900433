import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {googleCloudFunctionUrls} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GoogleCloudFunctionService {

  mailUrl: string = googleCloudFunctionUrls.mailUrl;

  constructor(private http: HttpClient) { }

  public sendAdminEmail(payload: string): Observable<any> {
    return this.http.post<any>(this.mailUrl, payload);
  }

  handleSuccess() {
    console.info('admin successfully messaged')
    alert('debug information: email send');
  }

  handleError(error: HttpErrorResponse){
    return throwError(error);
  }

}
