import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerFilesProps {
  nfcDesign: string;
  logo: string;
  profilePic: string;
}

export class CustomerFiles extends ValueObject<CustomerFilesProps>{


  private constructor(props: CustomerFilesProps) {
    super(props);
  }

  public static create(props: CustomerFilesProps): CustomerFiles {
    return new CustomerFiles({...props})
  }

  public static initEmpty(): CustomerFiles {
    return CustomerFiles.create({logo: "", nfcDesign: "", profilePic: ""})
  }

  public static createFromForm(formData: FormGroup): CustomerFiles {
    const customer = CustomerFiles.initEmpty()

    // Logo
    if (formData.get('uploadFileForm.fileLogo')) {
      customer.props.logo = formData.get('uploadFileForm.fileLogo').value
    }

    // Profile
    if (formData.get('uploadFileForm.fileProfilePic')) {
      customer.props.profilePic = formData.get('uploadFileForm.fileProfilePic').value
    }

    // Nfc design
    if (formData.get('nfcFileUpload.nfcDesignFile')) {
      customer.props.nfcDesign = formData.get('nfcFileUpload.nfcDesignFile').value
    }

    return customer
  }
}
