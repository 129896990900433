import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { CustomerFormComponent } from './customer-form/customer-form.component';
import {ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {firebaseConfig} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {FileUploadModule} from 'ng2-file-upload';
import { FileUploadComponent } from './customer-form/shared/file-upload/file-upload.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentReceivedComponent } from './payment-received/payment-received.component';
import { NfcFileUploadComponent } from './customer-form/shared/nfc-file-upload/nfc-file-upload.component';
import { ChooseDesignComponent } from './customer-form/shared/choose-design/choose-design.component';
import { ChooseWalletDesignComponent } from './customer-form/shared/choose-wallet-design/choose-wallet-design.component';
import { ChooseQrStyleComponent } from './customer-form/shared/choose-qr-style/choose-qr-style.component';
import { ChooseNfcDesignComponent } from './customer-form/shared/choose-nfc-design/choose-nfc-design.component';
import { PersonalDataComponent } from './customer-form/shared/personal-data/personal-data.component';
import { JobDataComponent } from './customer-form/shared/job-data/job-data.component';
import { PersonalDataContactComponent } from './customer-form/shared/personal-data-contact/personal-data-contact.component';
import { PersonalDataCompanyComponent } from './customer-form/shared/personal-data-company/personal-data-company.component';
import { SocialMediaComponent } from './customer-form/shared/social-media/social-media.component';
import { UserdefinedButtonsComponent } from './customer-form/shared/userdefined-buttons/userdefined-buttons.component';
import { UserMessageToUsComponent } from './customer-form/shared/user-message-to-us/user-message-to-us.component';
import { LegalConsentComponent } from './customer-form/shared/legal-consent/legal-consent.component';
import { FormBasicComponent } from './customer-form/form-basic/form-basic.component';
import { FormSmartComponent } from './customer-form/form-smart/form-smart.component';
import { BuyBasicComponent } from './buy-products/buy-basic/buy-basic.component';
import { BuySmartComponent } from './buy-products/buy-smart/buy-smart.component';

@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent,
    PaymentReceivedComponent,
    NfcFileUploadComponent,
    ChooseDesignComponent,
    ChooseWalletDesignComponent,
    ChooseQrStyleComponent,
    ChooseNfcDesignComponent,
    PersonalDataComponent,
    JobDataComponent,
    PersonalDataContactComponent,
    PersonalDataCompanyComponent,
    SocialMediaComponent,
    UserdefinedButtonsComponent,
    UserMessageToUsComponent,
    LegalConsentComponent,
    FormBasicComponent,
    FormSmartComponent,
    BuyBasicComponent,
    BuySmartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FileUploadModule,
    AngularFireModule.initializeApp(firebaseConfig),
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
