import {v4 as uuidv4} from 'uuid';
import {CustomerFiles} from "./customer/vo/customer.files";
import {CustomerButtons} from "./customer/vo/customer.buttons";
import {CustomerNfcCardStyle} from "./customer/vo/customer.nfc.card.style";
import {CustomerWalletStyle} from "./customer/vo/customer.wallet.style";
import {CustomerSocialMediaUrl} from "./customer/vo/customer.socialMedia.url";
import {CustomerQrCodeStyle} from "./customer/vo/customer.qrCode.style";
import {CustomerName} from "./customer/vo/customer.name";
import {CustomerPersonalAddress} from "./customer/vo/customer.personal.address";
import {CustomerCompany} from "./customer/vo/customer.company";
import {CustomerPersonalContact} from "./customer/vo/customer.personal.contact";
import {CustomerJob} from "./customer/vo/customer.job";
import {CustomerPurchase} from "./customer/vo/customer.purchase";
import {CustomerAdministration} from "./customer/vo/customer.administration";
import {FormGroup} from "@angular/forms";
import {Entity} from "../entity/entity";
import {UniqueEntityID} from "../entity/unique.entity.id";


export interface CustomerProps {
  name: CustomerName;
  address: CustomerPersonalAddress;
  contact: CustomerPersonalContact;
  job: CustomerJob;
  company: CustomerCompany;
  socialMedia: CustomerSocialMediaUrl;
  administration: CustomerAdministration;
  purchase: CustomerPurchase;
  files: CustomerFiles;
  design: string;
  qrCode: CustomerQrCodeStyle;
  walletStyle: CustomerWalletStyle;
  nfcCardStyle: CustomerNfcCardStyle;
  buttons: CustomerButtons;
  message: string;
}

export class Customer extends Entity<CustomerProps> {

  protected constructor(props: CustomerProps, uid?: UniqueEntityID) {
    super(props, uid)
  }

  public static create(props: CustomerProps, uid?: UniqueEntityID) {
    return new Customer({...props}, uid)
  }

  public static initEmptyCustomer(): Customer {
    return Customer.create({
      address: CustomerPersonalAddress.initEmpty(),
      administration: CustomerAdministration.initEmpty(),
      buttons: CustomerButtons.initEmpty(),
      company: CustomerCompany.initEmpty(),
      contact: CustomerPersonalContact.initEmpty(),
      design: "bitte wählen",
      files: CustomerFiles.initEmpty(),
      job: CustomerJob.initEmpty(),
      message: "",
      name: CustomerName.initEmpty(),
      nfcCardStyle: CustomerNfcCardStyle.initEmpty(),
      purchase: CustomerPurchase.initEmpty(),
      qrCode: CustomerQrCodeStyle.initEmpty(),
      socialMedia: CustomerSocialMediaUrl.initEmpty(),
      walletStyle: CustomerWalletStyle.initEmpty()
    });
  }

  public static createFromForm(formData: FormGroup): Customer {
    const address: CustomerPersonalAddress = CustomerPersonalAddress.createFromFrom(formData)
    const administration: CustomerAdministration = CustomerAdministration.createFromForm(formData)
    const files: CustomerFiles = CustomerFiles.createFromForm(formData)
    const buttons: CustomerButtons = CustomerButtons.createFromForm(formData)
    const company: CustomerCompany = CustomerCompany.createFromForm(formData)
    const contact: CustomerPersonalContact = CustomerPersonalContact.createFromForm(formData)
    const job: CustomerJob = CustomerJob.createFromForm(formData)
    const name: CustomerName = CustomerName.createFromForm(formData)
    const nfcCardStyle: CustomerNfcCardStyle = CustomerNfcCardStyle.createFromForm(formData)
    const purchase: CustomerPurchase = CustomerPurchase.initEmpty()
    const qrCode: CustomerQrCodeStyle = CustomerQrCodeStyle.createFromForm(formData)
    const socialMedia: CustomerSocialMediaUrl = CustomerSocialMediaUrl.createFromForm(formData)
    const walletStyle: CustomerWalletStyle = CustomerWalletStyle.createFromForm(formData)
    const message = formData.get('userMessage.message').value;
    const design = formData.get('chooseDesignForm.design').value;

    return Customer.create({
      address: address,
      administration: administration,
      buttons: buttons,
      company: company,
      contact: contact,
      design: design,
      files: files,
      job: job,
      message: message,
      name: name,
      nfcCardStyle: nfcCardStyle,
      purchase: purchase,
      qrCode: qrCode,
      socialMedia: socialMedia,
      walletStyle: walletStyle
    })

  }

  protected get didAcceptPrivacyPolicy() {
    return this.props.administration.props.didAcceptPrivacyPolicy;
  }

  acceptPrivacyPolicy() {
    this.props.administration.props.didAcceptPrivacyPolicy = true;
    this.props.administration.props.didAcceptPrivacyPolicy_timestamp = Date.now();
  }

  get id(): string {
    return this._id.toValue().toString();
  }

  toJson(): string {
    return JSON.stringify(this, null, 4);
  }
}


// Converts JSON strings to/from your types
export class Convert {
  public static toCustomer(json: string): Customer {
    return JSON.parse(json);
  }

}
