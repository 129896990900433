<h2>Daten Upload</h2>

<p>Bitte achte darauf, dass die Bilddaten über eine ausreichende Auflösung verfügen.</p>

<div formGroupName="uploadFileForm">

    <div class="input-group mb-12">
      <div class="custom-file">
        <input type="file" class="custom-file-input" multiple formControlName="fileLogo" id="fileLogo" (change)="onFileChangeLogo($event)">
        <label class="custom-file-label" #labelLogoFile for="fileLogo">
          <i class="fa fa-search" aria-hidden="true"></i> Ihr Logo
        </label>
      </div>
    </div>

  <div class="input-group mb-12">
    <div class="custom-file">
      <input type="file" class="custom-file-input" multiple formControlName="fileProfilePic" id="fileProfilePic" name="fileProfilePic" (change)="onFileChangeProfilePic($event)">
      <label class="custom-file-label" #labelProfilePic for="fileProfilePic">
        <i class="fa fa-search" aria-hidden="true"></i> Ihr Profilbild
      </label>
    </div>
  </div>

</div>
