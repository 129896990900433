import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";
import {FileUploadComponent} from "../shared/file-upload/file-upload.component";
import {NfcFileUploadComponent} from "../shared/nfc-file-upload/nfc-file-upload.component";
import {ChooseDesignComponent} from "../shared/choose-design/choose-design.component";
import {ChooseWalletDesignComponent} from "../shared/choose-wallet-design/choose-wallet-design.component";
import {ChooseQrStyleComponent} from "../shared/choose-qr-style/choose-qr-style.component";
import {ChooseNfcDesignComponent} from "../shared/choose-nfc-design/choose-nfc-design.component";
import {PersonalDataComponent} from "../shared/personal-data/personal-data.component";
import {JobDataComponent} from "../shared/job-data/job-data.component";
import {PersonalDataContactComponent} from "../shared/personal-data-contact/personal-data-contact.component";
import {PersonalDataCompanyComponent} from "../shared/personal-data-company/personal-data-company.component";
import {SocialMediaComponent} from "../shared/social-media/social-media.component";
import {UserdefinedButtonsComponent} from "../shared/userdefined-buttons/userdefined-buttons.component";
import {UserMessageToUsComponent} from "../shared/user-message-to-us/user-message-to-us.component";
import {LegalConsentComponent} from "../shared/legal-consent/legal-consent.component";
import {Customer} from "../../shared/entities/customer";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {GoogleCloudFunctionService} from "../../service/googleCloudFunctions";
import {FileUploadService} from "../../service/fileUpload";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {throwError} from "rxjs";

@Component({
  selector: 'app-form-basic',
  templateUrl: './form-basic.component.html',
  styleUrls: ['./form-basic.component.css'],
  providers: [NgbCarouselConfig],
  encapsulation: ViewEncapsulation.None
})

export class FormBasicComponent implements OnInit {

  @ViewChild(FileUploadComponent) fileUploadComponent: FileUploadComponent;
  @ViewChild(ChooseDesignComponent) chooseDesignComponent: ChooseDesignComponent;
  @ViewChild(ChooseWalletDesignComponent) chooseWalletDesignComponent: ChooseWalletDesignComponent;
  @ViewChild(ChooseQrStyleComponent) chooseQrStyleComponent: ChooseQrStyleComponent;
  @ViewChild(ChooseNfcDesignComponent) chooseNfcDesignComponent: ChooseNfcDesignComponent;
  @ViewChild(PersonalDataComponent) personalDataComponent: PersonalDataComponent;
  @ViewChild(JobDataComponent) jobDataComponent: JobDataComponent;
  @ViewChild(PersonalDataContactComponent) personalDataContactComponent: PersonalDataContactComponent;
  @ViewChild(PersonalDataCompanyComponent) personalDataCompanyComponent: PersonalDataCompanyComponent;
  @ViewChild(SocialMediaComponent) socialMediaComponent: SocialMediaComponent;
  @ViewChild(UserdefinedButtonsComponent) userdefinedButtonsComponent: UserdefinedButtonsComponent;
  @ViewChild(UserMessageToUsComponent) userMessageToUsComponent: UserMessageToUsComponent;
  @ViewChild(LegalConsentComponent) legalConsentComponent: LegalConsentComponent;

  // Member properties
  customer: Customer;
  customerForm: FormGroup;

  submitted = false;
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private googleCloudFunctionService: GoogleCloudFunctionService,
    private fileUploadService: FileUploadService,
    private config: NgbCarouselConfig,
    private router: Router
  ) {
    config.interval = 2000;
    config.wrap = true;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationIndicators = true;
    config.showNavigationArrows = true;
  }

  // Lifecycle hooks
  /**
   * When the component is mounted:
   * 1. Build the form values and init validators
   * 2. Init an empty customer object
   */
  ngOnInit(): void {
    this.customer = Customer.initEmptyCustomer()


    this.customerForm = this.formBuilder.group({
      consent: this.formBuilder.group({
        didAcceptPrivacyPolicy: [false, Validators.pattern('true')],
        didAcceptTermsAndConditions: [false, Validators.pattern('true')],
        didAcceptNewsletter: [false],
      })
    });

  }

  // PUBLIC METHODS //

  /**
   * This method is called when the form button is pressed
   */
  async onSubmit() {
    this.validateAllFormFields(this.customerForm);
    this.submitted = true;

    if (this.customerForm.valid) {
      this._updateCustomer();
      this.loading = true;

      await this._sendAdminMail()
        .then(() => this.uploadFile())
        .then(() => this.wait(1000))
        .then(() => {
          // alert(this.customer.toJson());
          this.loading = false;
          this.changeRoute();
        });
    }
  }

  async wait(ms: number): Promise<void> {
    return new Promise(r => setTimeout(r, ms));
  }

  async changeRoute() {
    await this.router.navigate(['/payment-received']);
  }

  /**
   * Clean the form by resetting the formBuilder class
   * by its own method.
   */
  onReset() {
    this.submitted = false;
    this.customerForm.reset();
  }

  /**
   * touching all field in the form vor validation on method call
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      // console.log(field, '<-- touching');
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  // GETTER & SETTERS //

  get isProduction(): boolean {
    return environment.production;
  }

  isInvalid(): boolean {
    return this.customerForm.invalid
  }

  isValid(): boolean {
    return this.customerForm.valid
  }

  /**
   * convenience getter for easy access to form fields.
   */
  get form() {
    return this.customerForm.controls;
  }

  // PRIVATE METHODS //

  async uploadFile() {
    const files: File[] = [];

    if (this.fileUploadComponent.uploadFileLogo) {
      files.push(this.fileUploadComponent.uploadFileLogo);
    }

    if (this.fileUploadComponent.uploadFileProfilePic) {
      files.push(this.fileUploadComponent.uploadFileProfilePic);
    }

    if (files.length > 0) {
      for (const file of files) {
        console.log('file to upload', file);
        await this.fileUploadService.startUpload(file, this.customer);
        await this.fileUploadService.snapshot.toPromise()
          .then(() => {
            console.log('upload finished')
          })
          .catch(error => this._handleError(error));
      }
    }

    await this.fileUploadService.uploadFormCopyToDb(this.customer, files);
  }

  async _sendAdminMail() {
    await this.googleCloudFunctionService.sendAdminEmail(this.customer.toJson()).toPromise()
      .catch(error => this._handleError(error));
  }

  _handleError(error: HttpErrorResponse) {
    let errorMessage = 'Leider gab es einen Fehler. Bitte versuche es erneut. Wenn das Problem weiterhin besteht, wende dich an unseren den Kundendienst.';
    let status = error.status

    if (status !== 200) {
      if (error.error instanceof ErrorEvent) {
        // Client-side errors
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // Server-side errors
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
    }
  }

  /**
   * Build the object from the form input.
   * Will be later passed to database and auth service.
   * @private
   */
  _updateCustomer() {

    this.customer = Customer.createFromForm(this.customerForm)

    if (!this.isProduction) {
      console.log(this.customer);
    }
  }

}
