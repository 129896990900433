import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {AngularFirestore} from '@angular/fire/firestore';
import {finalize, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Customer} from "../shared/entities/customer";
import {environment} from "../../environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  task: AngularFireUploadTask;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(private storage: AngularFireStorage,
              private db: AngularFirestore) {
  }

  assertFile(input) {
    if (input !== null) { return; }
    else { throw new Error('File to upload is empty'); }
  }

  startUpload(file: File, customer: Customer) {
    this.assertFile(file);

    const path = this.buildDownloadUrl(file, customer)

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, file)

    // Progress monitoring
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(console.log),
      // The file's download URL
      finalize( async () =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
      }),
    );
  }

  async uploadFormCopyToDb(customer: Customer, files?: File[]) {
    const filesPaths: string[] = []

    if (files) {
      files.map((file) => filesPaths.push(this.buildDownloadUrl(file, customer)))
    }

    const dateString = new Date().toISOString().slice(0,10);
    await this.db.collection('customerForm').doc(customer.id).set({
      timestamp: Date.now(),
      date: dateString,
      userUid: customer.id,
      files: filesPaths,
      formData: customer.toJson()});
  }

  buildDownloadUrl(file: File, customer: Customer): string {
    // The storage path
    const date: string = new Date().toISOString().slice(0,10);
    return `customerForm/${date}/${customer.id}/${Date.now()}_${file.name}`
  }


}
