export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyBFkuNSsLMikP8NQ_3v8KXWiaB2eNxLknw",
  authDomain: "linqed-staging.firebaseapp.com",
  databaseURL: "https://linqed-staging.firebaseio.com",
  projectId: "linqed-staging",
  storageBucket: "linqed-staging.appspot.com",
  messagingSenderId: "1059478358000",
  appId: "1:1059478358000:web:87b2570e43b1c06541ff35",
  measurementId: "G-SSMMFHE2T4"
};

export const googleCloudFunctionUrls = {
  mailUrl: "https://us-central1-linqed-staging.cloudfunctions.net/app/mail"
}

export const paypalConfig = {
  apiUrl: "https://www.paypal.com/sdk/js?client-id=AT8TYxnzMOvkqN8Ixh-JjzBgask83FIlbvvo0fTXhHJMpB0KqeGcScYK0OJhGdx5yc14NY6mE5ZTLrhr&currency=EUR&vault=true&locale=de_DE",
  basicPlanId: 'P-82N43995SY7290817L24VAYQ',
  basicProductId: 'PROD-98K72771FM049263A',
  smartPlanId: 'P-4J216954W4473452RL46TH4Y',
  smartProductId: 'PROD-6FY925824J270433A'
}
