<!-- SOCIAL MEDIA GROUP -->
<h2>Social-Media</h2>
<div formGroupName="socialMedia" class="form-row">

  <div class="form-group col-sm-12">
    <img class="img-fluid button-image" src="assets/img/social-media.png" alt="social media">
    <p>Je nach gewähltem Design lassen sich mehr oder weniger Profile einblenden. Wir empfehlen zwischen 3 und 6
      hinterlegten Profilen.</p>
  </div>

  <!-- xingUrl -->
  <div class="form-group col-sm-12">
    <label for="socialMedia1">Social-Media-Profil Nr. 1</label>
    <input type="text" formControlName="socialMedia1" id="socialMedia1" class="form-control"/>
  </div>
  <!-- xingUrl end -->

  <!-- linkedInUrl -->
  <div class="form-group col-sm-12">
    <label for="socialMedia2">Social-Media-Profil Nr. 2</label>
    <input type="text" formControlName="socialMedia2" id="socialMedia2" class="form-control"/>
  </div>
  <!-- linkedInUrl end -->

  <!-- facebookUrl -->
  <div class="form-group col-sm-12">
    <label for="socialMedia3">Social-Media-Profil Nr. 3</label>
    <input type="text" formControlName="socialMedia3" id="socialMedia3" class="form-control"/>
  </div>
  <!-- facebookUrl end -->

  <!-- twitterUrl -->
  <div class="form-group col-sm-12">
    <label for="socialMedia4">Social-Media-Profil Nr. 4</label>
    <input type="text" formControlName="socialMedia4" id="socialMedia4" class="form-control"/>
  </div>
  <!-- twitterUrl end -->

  <!-- instagramUrl -->
  <div class="form-group col-sm-12">
    <label for="socialMedia5">Social-Media-Profil Nr. 5</label>
    <input type="text" formControlName="socialMedia5" id="socialMedia5" class="form-control"/>
  </div>
  <!-- instagramUrl end -->

  <!-- githubUrl -->
  <div class="form-group col-sm-12">
    <label for="socialMedia6">Social-Media-Profil Nr. 6</label>
    <input type="text" formControlName="socialMedia6" id="socialMedia6" class="form-control"/>
  </div>
  <!-- githubUrl end -->
</div>
<!-- SOCIAL MEDIA GROUP END -->
