import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-user-message-to-us',
  templateUrl: './user-message-to-us.component.html',
  styleUrls: ['./user-message-to-us.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class UserMessageToUsComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('userMessage',
      this.formBuilder.group({
        message: [null]
      })
    );
  }

}
