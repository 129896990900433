<!-- NFC CARD SECTION START -->
<div formGroupName="nfcCardStyle">
  <div class="row NFC Card intro">
    <div class="col-sm-12">
      <h2 >NFC Card</h2>
      <p >Du kannst aus vorgefertigten Designs auswählen oder ein eigenes Layout hochladen. Sollten wir Rückfragen oder Änderungsvorschläge haben, melden wir uns im Vorfeld bei dir.</p>
    </div>
  </div>

  <div class="row Frontpage title">
    <div class="col-sm-12">
      <h4 class="text-center">Vorderseite</h4>
    </div>
  </div>

  <!-- front -->
  <div formGroupName="front" class="form-row">
    <div class="row frontpageItems">
      <!-- -->
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_frontside_01.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="one" formControlName="one" value="1">
          <label class="form-check-label" for="one">Design 1</label>
        </div>
      </div>
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_frontside_02.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="two" formControlName="two" value="1">
          <label class="form-check-label" for="two">Design 2</label>
        </div>
      </div>

      <!-- -->
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_frontside_03.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="three" formControlName="three" value="1">
          <label class="form-check-label" for="three">Design 3</label>
        </div>
      </div>
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_frontside_04.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="four" formControlName="four" value="1">
          <label class="form-check-label" for="four">Design 4</label>
        </div>
      </div>

      <!-- -->
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_frontside_05.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="five" formControlName="five" value="1">
          <label class="form-check-label" for="five">Design 5</label>
        </div>
      </div>
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_frontside_06.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="six" formControlName="six" value="1">
          <label class="form-check-label" for="six">Design 6</label>
        </div>
      </div>
    </div>
  </div>

  <!-- back -->
  <div formGroupName="back">
    <div class="row backpageTitle">
      <div class="col-sm-12 ">
        <h4 class="text-center">Rückseite</h4>
      </div>
    </div>

    <div class="row backpageItems">
      <!-- -->
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_backside_01.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="one" formControlName="one" value="1">
          <label class="form-check-label" for="one">Rückseite 1</label>
        </div>
      </div>
      <div class="col-sm-6" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_backside_02.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="two" formControlName="two" value="1">
          <label class="form-check-label" for="two">Rückseite 2</label>
        </div>
      </div>
      <!-- -->
      <div class="col-sm-12" align="center">
        <img class="border rounded img-fluid" src="assets/img/nfc_card_backside_03.png" style="width: 350px;">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="three" formControlName="three" value="1">
          <label class="form-check-label" for="three">Rückseite 3</label>
        </div>
      </div>
    </div>
  </div>

</div>
