import {SubscriptionProduct} from "../../shared/entities/product.subscription";
import {Price} from "../../shared/entities/price";
import {paypalConfig} from "../../../environments/environment";

export class ProductsAvailable {

  static basicProduct: SubscriptionProduct = {
    payPalData : {
      planId: paypalConfig.basicPlanId,
    },
    price: new Price(10.92, 0.19)
  };

  static smartProduct: SubscriptionProduct = {
    payPalData: {
      planId: paypalConfig.smartPlanId,
    },
    price: new Price(16.80, 0.19)
  }


}
