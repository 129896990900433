import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {SubscriptionProduct} from "../../shared/entities/product.subscription";
import {Router} from "@angular/router";
import {ProductsAvailable} from "../entity/products.available";
import {paypalConfig} from "../../../environments/environment";

// live on the window object
declare var paypal;

@Component({
  selector: 'app-buy-basic',
  templateUrl: './buy-basic.component.html',
  styleUrls: ['./buy-basic.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class BuyBasicComponent implements OnInit {
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;

  // Member properties //
  subscriptionId = null;
  didFinishWithError = false;
  didPay = false;

  // Products
  basicProduct: SubscriptionProduct;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.basicProduct = ProductsAvailable.basicProduct;
    this._buildPayPalButton(this.basicProduct);
  }

  async changeRoute() {
    await this.router.navigate(['/basic-form']);
  }

  get paypalApiUrl(): string {
    return paypalConfig.apiUrl
  }

  // Private methods //

  _buildPayPalButton(product: SubscriptionProduct) {
    return paypal
      .Buttons({
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: product.payPalData.planId
          });
        },
        onApprove: async (data, actions) => {
          this.subscriptionId = data.subscriptionID;
          this.didPay = true;

          // Store result in session
          await localStorage.setItem('userDidPayBasic', JSON.stringify({ didPay: true}));

        },
        onError: err => {
          this.didFinishWithError = true;
          console.log(err);
        }
      })
      .render(this.paypalElement.nativeElement);
  }
}
