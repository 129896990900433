import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerAdministrationProps {
  didAcceptPrivacyPolicy: boolean;
  didAcceptPrivacyPolicy_timestamp: number;
  didAcceptTermsAndConditions: boolean;
  didAcceptTermsAndConditions_timestamp: number;
  didAcceptNewsletter: boolean;
  didAcceptNewsletter_timestamp: number;
  isBlacklisted: boolean;
  created_timestamp: number;
}

export class CustomerAdministration extends ValueObject<CustomerAdministrationProps> {

  private constructor(props: CustomerAdministrationProps) {
    super(props);
  }

  public static create(props: CustomerAdministrationProps): CustomerAdministration {
    return new CustomerAdministration({...props})
  }

  public static initEmpty(): CustomerAdministration {
    return CustomerAdministration.create({
      created_timestamp: 0,
      didAcceptNewsletter: false,
      didAcceptNewsletter_timestamp: 0,
      didAcceptPrivacyPolicy: false,
      didAcceptPrivacyPolicy_timestamp: 0,
      didAcceptTermsAndConditions: false,
      didAcceptTermsAndConditions_timestamp: 0,
      isBlacklisted: false
    })
  }

  public static createFromForm(formData: FormGroup): CustomerAdministration {
    if (formData.get('consent')) {
      return CustomerAdministration.create({
        created_timestamp: Date.now(),
        didAcceptNewsletter: formData.get('consent.didAcceptNewsletter').value,
        didAcceptNewsletter_timestamp: Date.now(),
        didAcceptPrivacyPolicy: formData.get('consent.didAcceptPrivacyPolicy').value,
        didAcceptPrivacyPolicy_timestamp: Date.now(),
        didAcceptTermsAndConditions: formData.get('consent.didAcceptTermsAndConditions').value,
        didAcceptTermsAndConditions_timestamp: Date.now(),
        isBlacklisted: false
      })
    } else {
      return CustomerAdministration.initEmpty()
    }
  }

}
