<h2 class="text-center">Design-Auswahl</h2>

<div>
  <ngb-carousel class="col-12 justify-content-center slick-slide">
    <ng-template ngbSlide *ngFor="let image of images">
      <div class="item">
        <img class="img-fluid cellphone" [src]=image.imgSrc alt="{{image.description}}}">
      </div>
      <div class="carousel-caption">
        <h3>{{image.label}}</h3>
        <p>{{image.description}}</p>
      </div>
    </ng-template>
  </ngb-carousel>
</div>

<p>Bitte wähle das von dir gewünschte Design aus:</p>

<div formGroupName="chooseDesignForm">
  <select class="form-control" formControlName="design">
    <option disabled="disabled" selected="selected">Bitte wählen</option>
    <option value="Concrete">Concrete</option>
    <option value="Prim">Prim</option>
    <option value="Cactus">Cactus</option>
    <option value="Indigo">Indigo</option>
    <option value="Black Rock">Black Rock</option>
    <option value="Nero">Nero</option>
    <option value="Maize">Maize</option>
    <option value="Zodiac">Zodiac</option>
  </select>
</div>
