import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerNfcCardStyleProps {
  front: {
    one: boolean,
    two: boolean,
    three: boolean,
    four: boolean,
    five: boolean,
    six: boolean,
  },
  back: {
    one: boolean,
    two: boolean,
    three: boolean,
    four: boolean,
    five: boolean,
    six: boolean,
  }
}

export class CustomerNfcCardStyle extends ValueObject<CustomerNfcCardStyleProps> {

  private constructor(props: CustomerNfcCardStyleProps) {
    super(props);
  }

  public static create(props: CustomerNfcCardStyleProps): CustomerNfcCardStyle {
    return new CustomerNfcCardStyle({...props})
  }

  public static initEmpty(): CustomerNfcCardStyle {
    return CustomerNfcCardStyle.create({
      front: {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
      },
      back: {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
      }
    })
  }

  public static createFromForm(formData: FormGroup): CustomerNfcCardStyle {
    const nfcCardStyle = CustomerNfcCardStyle.initEmpty()

    if (formData.get('nfcCardStyle')) {
      if (formData.get('nfcCardStyle.front')) {
        nfcCardStyle.props.front.one = formData.get('nfcCardStyle.front.one').value;
        nfcCardStyle.props.front.two = formData.get('nfcCardStyle.front.two').value;
        nfcCardStyle.props.front.three = formData.get('nfcCardStyle.front.three').value;
        nfcCardStyle.props.front.four = formData.get('nfcCardStyle.front.four').value;
        nfcCardStyle.props.front.five = formData.get('nfcCardStyle.front.five').value;
        nfcCardStyle.props.front.six = formData.get('nfcCardStyle.front.six').value;
      }

      if (formData.get('nfcCardStyle.back')) {
        nfcCardStyle.props.back.one = formData.get('nfcCardStyle.back.one').value;
        nfcCardStyle.props.back.two = formData.get('nfcCardStyle.back.two').value;
        nfcCardStyle.props.back.three = formData.get('nfcCardStyle.back.three').value;
      }
    }

    return nfcCardStyle
  }

}
