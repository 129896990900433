import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerPurchaseProps {
  productId: string;
  isSubscription: boolean;
  expiryDate_timestamp: number;
  paymentFirstTimeOn_timestamp: number;
  paymentLastTimeOn_timestamp: number;
}

export class CustomerPurchase extends ValueObject<CustomerPurchaseProps> {

  private constructor(props: CustomerPurchaseProps) {
    super(props);
  }

  public static create(props: CustomerPurchaseProps): CustomerPurchase {
    return new CustomerPurchase({...props})
  }

  public static initEmpty(): CustomerPurchase {
    return CustomerPurchase.create({
      expiryDate_timestamp: 0,
      isSubscription: false,
      paymentFirstTimeOn_timestamp: 0,
      paymentLastTimeOn_timestamp: 0,
      productId: ""
    })
  }


}
