import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerNameProps {
  title: string;
  firstName: string;
  givenName: string;
}

export class CustomerName extends ValueObject<CustomerNameProps> {
  private constructor(props: CustomerNameProps) {
    super(props);
  }

  public static create(props: CustomerNameProps): CustomerName {
    return new CustomerName({...props})
  }

  public static initEmpty(): CustomerName {
    return CustomerName.create({firstName: "", givenName: "", title: ""})
  }

  public static createFromForm(formData: FormGroup): CustomerName {
    if (formData.get('name')) {
      return CustomerName.create({
          firstName: formData.get('name.firstName').value,
          givenName: formData.get('name.givenName').value,
          title: formData.get('name.title').value
      })
    } else {
      return CustomerName.initEmpty()
    }
  }
}
