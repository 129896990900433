<!-- CONSENT GROUP -->
<h2>Rechtliches</h2>
<div formGroupName="consent" class="form-row">

  <!-- privacyPolicy -->
  <div class="col-sm-12 form-check">
    <label for="didAcceptPrivacyPolicy">
      <input id="didAcceptPrivacyPolicy" type="checkbox" formControlName="didAcceptPrivacyPolicy">
      Ich willige ein, dass LINQED.de meine personenbezogenen Daten gemäßt ihrer <a
      href="https://linqed.webflow.io/datenschutzvereinbarung" target="_blank"> Datenschutzvereinbarung</a>
      erhebt und verarbeitet. *

      <div
        *ngIf="form.get('consent.didAcceptPrivacyPolicy').invalid && (form.get('consent.didAcceptPrivacyPolicy').touched)"
        class="invalid-feedback d-block">
        <div *ngIf="form.get('consent.didAcceptPrivacyPolicy').errors.pattern">Einwilligung wird
          benötigt.
        </div>
      </div>

    </label>
  </div>
  <!-- privacyPolicy end -->

  <!-- ToC -->
  <div class="col-sm-12 form-check">
    <label for="didAcceptTermsAndConditions">
      <input id="didAcceptTermsAndConditions" type="checkbox" formControlName="didAcceptTermsAndConditions">
      Ich willige ein, dass die <a href="https://linqed.webflow.io/agb" target="_blank">Allgemeinen
      Geschäftsbedingungen</a> von LINQED.de gelten. *

      <div
        *ngIf="form.get('consent.didAcceptTermsAndConditions').invalid && (form.get('consent.didAcceptTermsAndConditions').touched)"
        class="invalid-feedback d-block">
        <div *ngIf="form.get('consent.didAcceptTermsAndConditions').errors.pattern">Einwilligung wird benötigt.
        </div>
      </div>

    </label>

  </div>
  <!-- ToC end -->

  <!-- Newsletter -->
  <div class="col-sm-12 form-check">
    <label for="didAcceptNewsletter">
      <input id="didAcceptNewsletter" type="checkbox" formControlName="didAcceptNewsletter">
      Ich willige ein, dass mir LINQED.de per E-Mail Informationen und Angebote zu weiteren Produkten oder
      Dienstleistungen zum Zwecke der Werbung zusendet.
    </label>
  </div>
  <!-- Newsletter end -->

  <div class="col-sm-12">
    <p><i>* Einwilligung wird benötigt</i></p>
  </div>
</div>
<!-- CONSENT GROUP END -->
