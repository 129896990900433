import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerWalletStyleProps {
  black: boolean,
  white: boolean,
  blue: boolean
}

export class CustomerWalletStyle extends ValueObject<CustomerWalletStyleProps> {

  private constructor(props: CustomerWalletStyleProps) {
    super(props);

  }

  public static create(props: CustomerWalletStyleProps): CustomerWalletStyle {
    return new CustomerWalletStyle({...props})
  }

  public static initEmpty(): CustomerWalletStyle {
    return CustomerWalletStyle.create({black: false, blue: false, white: false})
  }

  public static createFromForm(formData: FormGroup) {
    if (formData.get('chooseWalletForm')) {
      return CustomerWalletStyle.create({
        black: formData.get('chooseWalletForm.black').value,
        blue: formData.get('chooseWalletForm.blue').value,
        white: formData.get('chooseWalletForm.white').value,
      })
    }
  }

}
