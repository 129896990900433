import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {FileUploadService} from '../../../service/fileUpload';
import {UploadFile} from './uploadFile';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FileUploadComponent implements OnInit {

  @Input() uploadFileLogo: File = null;
  @Input() uploadFileProfilePic: File = null;
  // uploadFiles: File[] = [];

  @ViewChild('labelLogoFile')
  labelLogoFile: ElementRef;

  @ViewChild('labelProfilePic')
  labelProfilePic: ElementRef;

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('uploadFileForm',
      this.formBuilder.group({
        fileLogo: [null],
        fileProfilePic: [null]
      }));
  }

  get logoFile() {
    return this.uploadFileLogo
  }

  get profilePicFile() {
    return this.uploadFileProfilePic
  }

  onFileChangeLogo(event) {
    const files = event.target.files;
    this.setLabelLogoFile(files);

    this.uploadFileLogo = files.item(0);
    console.log('logo:', this.uploadFileLogo)
    // this.uploadFiles.push(this.uploadFileLogo);
  }

  onFileChangeProfilePic(event) {
    const files = event.target.files;
    this.setLabelProfilePic(files);

    this.uploadFileProfilePic = files.item(0);
    console.log('profilePic:', this.uploadFileProfilePic)
    // this.uploadFiles.push(this.uploadFileProfilePic);
  }

  setLabelLogoFile(files: FileList) {
    this.labelLogoFile.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
  }

  setLabelProfilePic(files: FileList) {
    this.labelProfilePic.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
  }

}
