import {ValueObject} from "../../../valueObject/value.object";
import {FormGroup} from "@angular/forms";

export interface CustomerCompanyProps {
  name: string;
  legalForm: string;
  registerCourt: string;
  registerNumber: string;
  url: string;
  street: string;
  streetNr: string;
  postalCode: string;
  city: string;
  stateOrProvince: string;
  country: string;
}

export class CustomerCompany extends ValueObject<CustomerCompanyProps> {

  private constructor(props: CustomerCompanyProps) {
    super(props);
  }

  public static create(props: CustomerCompanyProps): CustomerCompany {
    return new CustomerCompany({...props})
  }

  public static initEmpty(): CustomerCompany {
    return CustomerCompany.create({
      city: "",
      country: "",
      legalForm: "",
      name: "",
      postalCode: "",
      registerCourt: "",
      registerNumber: "",
      stateOrProvince: "",
      street: "",
      streetNr: "",
      url: ""
    })
  }

  public static createFromForm(formData: FormGroup): CustomerCompany {
    const company: CustomerCompany = CustomerCompany.initEmpty()

    if (formData.get('company')) {
      company.props.registerCourt = formData.get('company.registerCourt').value;
      company.props.registerNumber = formData.get('company.registerNumber').value;
      company.props.legalForm = formData.get('company.legalForm').value;
      company.props.name = formData.get('company.name').value;
      company.props.url = formData.get('company.url').value;
    }

    if (formData.get('company.address')) {
      company.props.city = formData.get('company.address.city').value;
      company.props.country = formData.get('company.address.country').value;
      company.props.postalCode = formData.get('company.address.postalCode').value;
      company.props.stateOrProvince = formData.get('company.address.state').value;
      company.props.street = formData.get('company.address.street').value;
      company.props.streetNr = formData.get('company.address.streetNr').value;
    }

    return company
  }

}
