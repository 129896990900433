<!-- JOB GROUP -->
<h2>Tätigkeit</h2>
<div formGroupName="job" class="form-row">

  <!-- title -->
  <div class="form-group col-lg-6 col-md-push-6">
    <label for="titleJob">Stellenbezeichnung bzw. Position</label>
    <input type="text" formControlName="title" id="titleJob" class="form-control"/>
  </div>
  <!-- title end -->

  <!-- firstName -->
  <div class="form-group col-lg-6 col-md-pull-6">
    <label for="department">Abteilung</label>
    <input type="text" formControlName="department" id="department" class="form-control"/>
  </div>
  <!-- firstName end -->

</div>
<!-- JOB GROUP END -->
