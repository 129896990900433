import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-personal-data-company',
  templateUrl: './personal-data-company.component.html',
  styleUrls: ['./personal-data-company.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]

})
export class PersonalDataCompanyComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('company',
      this.formBuilder.group({
        name: [null],
        legalForm: [null],
        registerCourt: [null],
        registerNumber: [null],
        url: [null],
        address: this.formBuilder.group({
          street: [null],
          streetNr: [null],
          city: [null],
          postalCode: [null],
          state: [null],
          country: [null],
        }),
      })
    );
  }

}
