<!-- QR CODE STYLES GROUP -->


<div formGroupName="qrCode" class="form-row">
  <h2>QR-Code Auswahl</h2>
  <p>
    Wir senden dir drei QR-Code Styles, sowohl farbig als auch schwarzweiß. Wähle hier deine favorisierten
    Designs aus. Kontaktiere uns, falls du ein anderes Design wünschst!
  </p>

  <!-- First row -->
  <div class="col text-center">
    <img class="img-fluid qr-code" src="assets/img/classic.png" alt="style1">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="style01" formControlName="style01" value="1"/>
      <label class="form-check-label" for="style01">classic</label>
    </div>
  </div>

  <div class="col text-center">
    <img class="img-fluid qr-code" src="assets/img/classic+logo.png" alt="style2">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="style02" formControlName="style02" value="1"/>
      <label class="form-check-label" for="style02">scan me</label>
    </div>
  </div>

  <div class="col text-center">
    <img class="img-fluid qr-code" src="assets/img/classic+scan.png" alt="style3">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="style03" formControlName="style03" value="1"/>
      <label class="form-check-label" for="style03">classic & Logo</label>
    </div>
  </div>

</div>

<!-- second row -->
<div formGroupName="qrCode" class="form-row">
  <div class="col text-center">
    <img class="img-fluid qr-code"
         src="https://uploads-ssl.webflow.com/5eb157b2928e7b012923f185/5eb5b724ecd059fd1cfffec9_qr-code-6-p-500.png"
         alt="style4">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="style04" formControlName="style04" value="1"/>
      <label class="form-check-label" for="style04">classic & Bild</label>
    </div>
  </div>

  <div class="col text-center">
    <img class="img-fluid qr-code" src="assets/img/style2.png" alt="style2">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="style05" formControlName="style05" value="1"/>
      <label class="form-check-label" for="style05">modern</label>
    </div>
  </div>

  <div class="col text-center">
    <img class="img-fluid qr-code" src="assets/img/style5.png" alt="style5">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="style06" formControlName="style06" value="1"/>
      <label class="form-check-label" for="style06">pointed</label>
    </div>
  </div>

</div>
<!-- QR CODE STYLES GROUP END -->
