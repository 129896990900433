<!-- COMPANY GROUP -->
<h2>Firmendaten</h2>
<div formGroupName="company" class="form-row">

  <!-- companyName -->
  <div class="form-group col-sm-8">
    <label for="name">Firmenname</label>
    <input type="text" formControlName="name" id="name" class="form-control"/>
  </div>
  <!-- companyName end -->

  <!-- companyName -->
  <div class="form-group col-sm-4">
    <label for="legalForm">Rechtsform</label>
    <input type="text" formControlName="legalForm" id="legalForm" class="form-control"/>
  </div>
  <!-- companyName end -->

  <!-- registerCourt -->
  <div class="form-group col-sm-6">
    <label for="registerCourt">Registergericht</label>
    <input type="text" formControlName="registerCourt" id="registerCourt" class="form-control"/>
  </div>
  <!-- registerCourt end -->

  <!-- registerNumber -->
  <div class="form-group col-sm-6">
    <label for="registerNumber">Registernummer</label>
    <input type="text" formControlName="registerNumber" id="registerNumber" class="form-control"/>
  </div>
  <!-- registerNumber end -->

  <!-- registerNumber -->
  <div class="form-group col-sm-12">
    <label for="url">Website</label>
    <input type="text" formControlName="url" id="url" class="form-control"/>
  </div>
  <!-- registerNumber end -->

</div>

<!-- nested address group -->
<div formGroupName="company">
  <div formGroupName="address" class="form-row">

    <!-- street -->
    <div class="form-group col-lg-3 col-md-6">
      <label for="street">Strasse</label>
      <input type="text" formControlName="street" id="street" class="form-control"/>
    </div>
    <!-- street end -->

    <!-- streetNr -->
    <div class="form-group col-lg-1 col-md-3">
      <label for="streetNr">Nr.</label>
      <input type="text" formControlName="streetNr" id="streetNr" class="form-control"/>
    </div>
    <!-- streetNr end -->

    <!-- city -->
    <div class="form-group col-lg-3 col-md-3">
      <label for="city">Stadt</label>
      <input type="text" formControlName="city" id="city" class="form-control"/>
    </div>
    <!-- city end -->

    <!-- postalCode -->
    <div class="form-group col-lg-1 col-md-2">
      <label for="postalCode">PLZ</label>
      <input type="text" formControlName="postalCode" id="postalCode" class="form-control"/>
    </div>
    <!-- postalCode end -->

    <!-- state -->
    <div class="form-group col-lg-2 col-md-6">
      <label for="state">Bundesland</label>
      <input type="text" formControlName="state" id="state" class="form-control"/>
    </div>
    <!-- state end -->

    <!-- street -->
    <div class="form-group col-lg-2 col-md-4">
      <label for="country">Land</label>
      <input type="text" formControlName="country" id="country" class="form-control"/>
    </div>
    <!-- street end -->

  </div>
  <!-- COMPANY: ADDRESS GROUP END -->
</div>
<!-- COMPANY GROUP END -->
