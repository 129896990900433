<!-- NFC CARD UPLOAD START -->
<h4>Eigenes Design hochladen</h4>
<p>Du kannst uns auch dein eigenes Design für die NFC Card zukommen lassen. Bitte nutzt unsere Vorlage oder schau dir unsere Anforderungen an die Druckdaten genau an.
  <br>
  <br>
  <a href="http://web.linqed.de/vorlagen/Anforderung_Druckdaten.pdf" target="_blank">Anforderungen an die Druckdaten</a>
  <br>
  <a href="http://web.linqed.de/vorlagen/Vorlage-NFC-Card-Druck.psd" target="_blank">Photoshop PSD Vorlage</a>
</p>

<div formGroupName="nfcFileUpload">

  <div class="input-group mb-12">
    <div class="custom-file">
      <input type="file" class="custom-file-input" multiple formControlName="nfcDesignFile" id="nfcDesignFile" (change)="onNfcFileChangeDesign($event)">
      <label class="custom-file-label" #labelNfcFileDesign for="nfcDesignFile">
        <i class="fa fa-search" aria-hidden="true"></i> Eigenes Design
      </label>
    </div>
  </div>

</div>
