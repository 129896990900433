import {Component, OnInit} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup, FormGroupDirective} from "@angular/forms";

@Component({
  selector: 'app-choose-qr-style',
  templateUrl: './choose-qr-style.component.html',
  styleUrls: ['./choose-qr-style.component.css'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class ChooseQrStyleComponent implements OnInit {

  form: FormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('qrCode',
      this.formBuilder.group({
        style01: [null],
        style02: [null],
        style03: [null],
        style04: [null],
        style05: [null],
        style06: [null],
      }));
  }

}
